import React, { useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Select,
  MenuItem,
  Button,
  Dialog,
} from '@mui/material'
import { Header } from '../../Header/Header'
import Logo from '../../../assets/logo-nest.png'
import { clients } from '../../../utils'
import { CredentialModal } from '../../CredentialModal/CredentialModal'

export const HomePortalAccess = () => {
  const [organization, setOrganization] = useState('')
  const [open, setOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const handleChange = (event) => {
    setOrganization(event.target.value)
  }

  const handleGo = () => {
    const selected = clients.find((client) => client.name === organization)

    if (selected) {
      if (selected.name === 'Agencia Pólux' || selected.name === 'Soporte Tickets - MOP') {
        window.location.replace(selected.route)
        return
      }

      setSelectedClient(selected.route)
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedClient(null)
  }

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '80vh',
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h4">Portal Acceso Clientes</Typography>
          <Typography fontWeight="100" fontSize="16px">
            Selecciona tu organización para acceder a su respectivo portal
          </Typography>

          <Select
            style={{
              marginTop: '30px',
              marginBottom: '30px',
            }}
            value={organization}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value="">Seleccionar organización</MenuItem>
            {clients.map((client) => (
              <MenuItem key={client.name} value={client.name}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleGo}
            style={{ backgroundColor: '#FF735C' }}
            disabled={!organization}
          >
            IR
          </Button>
        </Stack>
        <img src={Logo} alt="Logo Nest" style={{ width: '500px' }} />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <CredentialModal selectedClient={selectedClient} />
      </Dialog>
    </>
  )
}
