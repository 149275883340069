export const clients = [
  {
    name: 'Agencia Pólux',
    route: 'https://agenciapolux.nestconsultores.cl/',
  },
  {
    name: 'Megaphone',
    route: 'https://megaphone.nestconsultores.cl/',
  },
  {
    name: 'Cerberus Legal',
    route: 'https://gestor-de-proyectos.cerberus-legal.com/iniciar-sesion',
  },
  {
    name: 'Universidad del Bío - Bío',
    route: 'https://ubb.nestconsultores.cl/',
  },
  {
    name: 'ANID',
    route: 'https://anid.nestconsultores.cl/',
  },
  {
    name: 'Soporte Tickets - MOP',
    route: 'https://ticketmop.nestconsultores.cl/',
  },
]
