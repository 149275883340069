import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import LogoNestWhite from '../../assets/logo-nest-blanco.png'
import LanguageIcon from '@mui/icons-material/Language'

export const Header = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#303030',
        height: '130px',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '130px',
          }}
        >
          <Box
            sx={{
              '& .img-header': {
                width: { xs: '200px', sm: '170px' },
                height: 'auto',
              },
            }}
          >
            <img
              src={LogoNestWhite}
              alt="NEST Consultores"
              className="img-header"
            />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Volver a la página de Nest">
              <Link target="_blank" to="https://nestconsultores.cl/">
                <LanguageIcon
                  sx={{ fontSize: { xs: '40px', sm: '30px' }, color: 'white' }}
                />{' '}
              </Link>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
