import { useState } from 'react'
import {
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'

export const CredentialModal = ({ selectedClient }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleLogin = async () => {
    setLoading(true)

    const loginDetails = {
      usuario: username,
      clave: password,
    }

    try {
      if (selectedClient === process.env.REACT_APP_ANID_WEB_URL) {
        if (
          loginDetails.usuario === process.env.REACT_APP_ANID_USER &&
          loginDetails.clave === process.env.REACT_APP_ANID_PASS
        ) {
          window.location.replace(selectedClient)
        } else {
          throw new Error('Error en el inicio de sesión')
        }
      } else if (selectedClient === process.env.REACT_APP_CERBERUS_WEB_URL) {
        if (
          loginDetails.usuario === process.env.REACT_APP_CERBERUS_USER &&
          loginDetails.clave === process.env.REACT_APP_CERBERUS_PASS
        ) {
          window.location.replace(selectedClient)
        } else {
          throw new Error('Error en el inicio de sesión')
        }
      } else if (selectedClient === process.env.REACT_APP_UBB_WEB_URL) {
        if (
          loginDetails.usuario === process.env.REACT_APP_UBB_USER &&
          loginDetails.clave === process.env.REACT_APP_UBB_PASS
        ) {
          window.location.replace(selectedClient)
        } else {
          throw new Error('Error en el inicio de sesión')
        }
      } else {
        throw new Error('Error en el inicio de sesión')
      }
    } catch (error) {
      setShowError(true)
      console.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="username"
          label="Usuario"
          type="text"
          fullWidth
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          margin="dense"
          id="password"
          label="Contraseña"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {showError && (
          <Typography color="error" sx={{ mt: 2 }}>
            Las credenciales son incorrectas. Por favor, intenta de nuevo.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleLogin}
          style={{ backgroundColor: '#ff735c', color: 'white' }}
          disabled={loading}
        >
          {loading ? 'Cargando...' : 'Acceder'}
        </Button>
      </DialogActions>
    </>
  )
}
